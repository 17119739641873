<template>
  <v-container>
    <back-toolbar title="onboarding.login"></back-toolbar>
    <v-img
      :src="require('@/assets/login.png')"
      width="calc(calc(100vh - 420px) - env(safe-area-inset-top))"
      height="calc(calc(100vh - 420px) - env(safe-area-inset-top))"
      class="mx-auto"
      :style="$vuetify.breakpoint.smAndUp ? 'max-width: 600px' : ''"
    ></v-img>
    <v-row>
      <v-col>
        <v-btn
          width="315"
          height="48"
          class="gradient d-block mx-auto Heading-White-H4-18-Center"
          color="primary"
          depressed
          @click="$router.safePush({ name: 'loginEmail' })"
        >
          {{ $t("login.loginEmail") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row dense class="mt-2 mb-4">
      <v-col cols="12">
        <div class="text-center">{{ $t("profile.or") }}</div>
      </v-col>
    </v-row>
    <v-layout row wrap>
      <v-flex xs2 offset-xs2>
        <v-img
          @click="$store.dispatch('session/facebookLogin')"
          src="@/assets/img/facebook.svg"
          width="48px"
          height="auto"
          class="social-login-button"
        ></v-img>
      </v-flex>
      <v-flex xs4>
        <v-img
          @click="$store.dispatch('session/googleLogin')"
          src="@/assets/img/google.svg"
          width="48px"
          height="auto"
          class="social-login-button"
        ></v-img>
      </v-flex>
      <v-flex xs2>
        <v-img
          @click="$store.dispatch('session/appleLogin')"
          src="@/assets/img/apple.svg"
          width="48px"
          height="auto"
          class="social-login-button"
        ></v-img>
        <v-spacer></v-spacer>
      </v-flex>
    </v-layout>
    <v-row>
      <v-col>
        <v-btn
          v-if="!showForgotPw && !$store.state.isMiKY"
          @click.native="$store.commit('session/CLEAR_AUTH_ERROR')"
          :to="{ name: 'register' }"
          min-height="40"
          width="100%"
          class="Heading-Color-H4-18-Center mt-4"
          depressed
          color="primary"
          text
          ><strong>{{ $t("login.registration") }}</strong></v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BackToolbar from "@/components/app/common/BackToolbar";

export default {
  components: {
    BackToolbar,
  },
  data() {
    return {
      showForgotPw: false,
      showErrorReset: false,
    };
  },
  watch: {
    "$store.state.session.resetStatus"(val) {
      this.showForgotPw = false;
      this.showErrorReset = false;

      if (val === "success") {
        this.showForgotPw = true;
      } else if (val === "failure") {
        this.showErrorReset = true;
      }
    },
  },
};
</script>
<style scoped>
.social-login-button {
  margin: auto;
  cursor: pointer;
}
</style>
